/* eslint-disable @typescript-eslint/naming-convention */

import { colors } from '@almond/ui';

// TODO extract these colors into an Artemis "theme"
export const streamColors = {
  '--str-chat__primary-surface-color': colors.FIG['05'],
  '--str-chat__primary-red-color': colors.ERROR['01'],
  '--str-chat__secondary-surface-color': colors.CURRANT['01'],
  '--str-chat__message-bubble-color': '#000000',
  '--str-chat__background-color': colors.CURRANT.WHITE,
  '--str-chat__notification-list-background-color': colors.CURRANT.WHITE,
  '--str-chat__avatar-background-color': colors.PEACH.BASIC,
  '--str-chat__avatar-color': colors.STRAWBERRY.BASIC,
  '--str-chat__date-separator-color': colors.CURRANT['03'],
  '--str-chat__placeholder-color': colors.CURRANT['03'],
  '--str-chat__message-edited-text': colors.FIG['03'], // only used by "Edited" message

  '--str-chat__admin-other-patient-surface-background': colors.STRAWBERRY['05'],

  // This renders the system font - San Fransisco on iOS/macOS, Roboto on Android, Segoe on Windows
  '--str-chat__font-family':
    'ui-sans-serif, system-ui, -system-ui, -apple-system, BlinkMacSystemFont, Helvetica, sans-serif',
};
