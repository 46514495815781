import React from 'react';
import { View } from 'react-native';

import { Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useSegments } from 'expo-router';

import { useSidebar } from '../../hooks';

import { themedStyles } from './styles';

export type HeaderProps = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  title?: string;
};

/* eslint-disable @typescript-eslint/naming-convention */
const titles: Record<string, string> = {
  messages: 'You & Care Team',
  '[conversationId]': 'Messages',
};
/* eslint-enable @typescript-eslint/naming-convention */

export const Header: React.FC<HeaderProps> = props => {
  const [styles] = useTheme(themedStyles);
  const segments = useSegments();
  const { isSidebarDisplayed } = useSidebar();
  const { isMobile } = useBrowserTypeMap();

  const currentPage =
    props.title ??
    (() => {
      if (isSidebarDisplayed) return 'More Info';

      const slug = segments[1] || '';

      if (slug in titles && isMobile) return titles[slug];

      return undefined;
    })();

  return (
    <View style={styles.header} testID="ArtemisHeader">
      <View style={styles.left}>{props.left}</View>
      <View style={styles.center}>
        <Text fontStyle="bold" size={currentPage ? 'xl' : 'xxl'} style={styles.centerText}>
          {currentPage || 'almond'}
        </Text>
      </View>
      <View style={styles.right}>{props.right}</View>
    </View>
  );
};
