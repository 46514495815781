/* eslint-disable @typescript-eslint/naming-convention */

import add from './materialIcons/add.svg';
import addAPhoto from './materialIcons/add-a-photo.svg';
import arrowBack from './materialIcons/arrow-back.svg';
import arrowCircleUp from './materialIcons/arrow-circle-up.svg';
import arrowUpward from './materialIcons/arrow-upward.svg';
import attachFile from './materialIcons/attach-file.svg';
import check from './materialIcons/check.svg';
import close from './materialIcons/close.svg';
import contentCopy from './materialIcons/content-copy.svg';
import del from './materialIcons/delete.svg';
import doneAll from './materialIcons/done-all.svg';
import download2 from './materialIcons/download-2.svg';
import error from './materialIcons/error.svg';
import info from './materialIcons/info.svg';
import keep from './materialIcons/keep.svg';
import keyboardArrowDown from './materialIcons/keyboard-arrow-down.svg';
import keyboardArrowUp from './materialIcons/keyboard-arrow-up.svg';
import markChatUnread from './materialIcons/mark-chat-unread.svg';
import mood from './materialIcons/mood.svg';
import moreVert from './materialIcons/more-vert.svg';
import progressActivity from './materialIcons/progress-activity.svg';
import refresh from './materialIcons/refresh.svg';
import replyAll from './materialIcons/reply-all.svg';
import search from './materialIcons/search.svg';
import upload2 from './materialIcons/upload-2.svg';

export const materialIcons = {
  add,
  'add-a-photo': addAPhoto,
  'arrow-back': arrowBack,
  'arrow-circle-up': arrowCircleUp,
  'arrow-upward': arrowUpward,
  'attach-file': attachFile,
  check,
  close,
  'content-copy': contentCopy,
  delete: del,
  'done-all': doneAll,
  'download-2': download2,
  error,
  info,
  keep,
  'keyboard-arrow-down': keyboardArrowDown,
  'keyboard-arrow-up': keyboardArrowUp,
  'mark-chat-unread': markChatUnread,
  mood,
  'more-vert': moreVert,
  'progress-activity': progressActivity,
  refresh,
  'reply-all': replyAll,
  search,
  'upload-2': upload2,
};

export { default as PdfIcon } from './pdf-icon.svg';
