import React, { useState } from 'react';

import { useTheme } from '@almond/ui';

import { ModalListPreview, useIsCloseDisabled } from '~/modules/documents';
import { ListModal } from '~/modules/ui';

import { useDocuments } from '../../hooks';
import { ConfirmDeleteModal } from '../ConfirmDeleteModal';

import { themedStyles } from './styles';

import type { ExtendedDocumentOut } from '~/modules/documents/types';
import type { ModalProps } from '~/modules/ui';

export type SharedMediaModalProps = Pick<ModalProps, 'isVisible' | 'onRequestClose'> & {
  items: readonly ExtendedDocumentOut[];
  patientUuid: string | null;
  editable?: boolean;
  onOpenPreview: (item: ExtendedDocumentOut) => void;
};

export const SharedMediaModal: React.FC<SharedMediaModalProps> = props => {
  const { patientUuid } = props;
  const [styles] = useTheme(themedStyles);
  const [documentToDelete, setDocumentToDelete] = useState<ExtendedDocumentOut | null>(null);
  const { deleteDocument, isDeleting, documentDeleteError, moveToHealthRecords } = useDocuments(patientUuid, 'media');
  const { isCloseDisabled, onHasMoveIntentChange } = useIsCloseDisabled(props.isVisible);

  const onConfirmDelete = async (document: ExtendedDocumentOut) => {
    if (!deleteDocument) return;

    await deleteDocument(document);
  };

  if (documentToDelete) {
    return (
      <ConfirmDeleteModal
        error={documentDeleteError}
        document={documentToDelete}
        isDeleting={isDeleting}
        onConfirmDelete={onConfirmDelete}
        onCancel={() => setDocumentToDelete(null)}
      />
    );
  }

  return (
    <ListModal
      {...props}
      testID="SharedMediaModal"
      title="Shared Media"
      keyProp="uuid"
      scrollViewStyle={styles.items}
      items={props.items}
      renderItem={item => (
        <ModalListPreview
          testID={item.filename}
          item={item}
          onDelete={props.editable ? (document: ExtendedDocumentOut) => setDocumentToDelete(document) : undefined}
          onMoveToHealthRecords={props.editable && moveToHealthRecords ? moveToHealthRecords : undefined}
          patientUuid={patientUuid}
          onOpenPreview={props.onOpenPreview}
          onHasMoveIntentChange={onHasMoveIntentChange}
        />
      )}
      isCloseDisabled={isCloseDisabled}
    />
  );
};
