import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    ...getShadow(),
    backgroundColor: theme.background,
    paddingHorizontal: sizes.SPACING_M,
    paddingVertical: sizes.SPACING_L,
    borderRadius: sizes.SPACING_XS,
    rowGap: sizes.SPACING_S,
    alignItems: 'center',
    justifyContent: 'center',
  },

  title: {
    color: theme.darkText,
    marginBottom: sizes.SPACING_S,
  },

  buttonAlert: {
    borderColor: theme.accent,
  },
}));
