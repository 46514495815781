import React from 'react';
import { View } from 'react-native';

import {
  ConnectedCheckbox,
  ConnectedDateInput,
  ConnectedPhoneNumberInput,
  ConnectedTextInput,
  ScrollView,
  SmsLabel,
  SubmitButton,
  Text,
  useFormContext,
  useTheme,
} from '@almond/ui';

import { checkShouldVerifyPhoneNumber } from './checkShouldVerifyPhoneNumber';

import { themedStyles } from './styles';

import type { DefaultDemographicFormValues } from './types';

export type DemographicForm = {
  defaultValues: DefaultDemographicFormValues;
  errorMessage?: string;
  isAdmin?: boolean;
};

export const DemographicForm = (props: DemographicForm) => {
  const { defaultValues, errorMessage, isAdmin } = props;
  const [styles] = useTheme(themedStyles);
  const formContext = useFormContext();
  const phone = formContext.watch('phone');
  const isOptedIntoSms = !!formContext.watch('isOptedIntoSms');

  const submitTitle = (() => {
    if (isAdmin) {
      return 'Save';
    }

    const shouldVerifyPhoneNumber = checkShouldVerifyPhoneNumber(phone, isOptedIntoSms, defaultValues);

    if (shouldVerifyPhoneNumber) {
      return 'Save & Verify';
    }

    return 'Save';
  })();

  return (
    <ScrollView style={styles.container}>
      <View style={styles.contentContainer}>
        <View style={styles.row}>
          <ConnectedTextInput
            name="firstName"
            label="First Name*"
            textContentType="givenName"
            style={styles.textInput}
            containerStyle={styles.textInputContainer}
            testID="FirstName"
          />
          <ConnectedTextInput
            name="preferredFirstName"
            label="Preferred First Name (optional)"
            textContentType="nickname"
            style={styles.textInput}
            containerStyle={styles.textInputContainer}
            testID="PreferredFirstName"
          />
        </View>
        <View style={styles.row}>
          <ConnectedTextInput
            name="lastName"
            label="Last Name*"
            textContentType="familyName"
            style={styles.textInput}
            containerStyle={styles.textInputContainer}
            testID="LastName"
          />
          <ConnectedDateInput
            name="birthday"
            label="Birth Date*"
            style={styles.textInput}
            containerStyle={styles.textInputContainer}
            testID="Birthday"
          />
        </View>
        <View style={styles.row}>
          <ConnectedPhoneNumberInput
            name="phone"
            label="Cell Phone*"
            textContentType="telephoneNumber"
            style={styles.textInput}
            containerStyle={styles.textInputContainer}
            isDisabled={isAdmin}
            testID="PhoneNumber"
          />
          <ConnectedTextInput
            name="email"
            label="Email Address*"
            inputMode="email"
            textContentType="emailAddress"
            style={styles.textInput}
            containerStyle={styles.textInputContainer}
            isDisabled={!isAdmin}
            testID="Email"
          />
        </View>
        <View style={styles.row}>
          <View style={styles.textInputContainer}>
            {!isAdmin && <ConnectedCheckbox name="isOptedIntoSms" label={() => <SmsLabel />} />}
          </View>
        </View>
      </View>
      {errorMessage && <Text style={styles.error}>{`* ${errorMessage}`}</Text>}
      <View style={styles.footer}>
        <SubmitButton
          style={styles.button}
          fixedWidth={false}
          requiredFields={['firstName', 'lastName', 'birthday', 'email', 'phone']}
          testID="Submit"
        >
          {submitTitle}
        </SubmitButton>
      </View>
    </ScrollView>
  );
};
