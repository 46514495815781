import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  leftSidebar: {
    alignItems: 'stretch',
    justifyContent: 'center',
    backgroundColor: theme.lightSecondaryBackground,
    width: 230,
  },
  leftSidebarMobile: {
    width: '100%',
  },
  leftSidebarMobileHidden: {
    width: 0,
  },
}));
