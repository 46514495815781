import React from 'react';

import { Text, useBrowserType, useTheme } from '@almond/ui';
import { useSegments } from 'expo-router';

import { useAdminSidebar } from '../../hooks';
import { BackButton } from './BackButton';

import { themedStyles } from './styles';

export const Left: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const segments = useSegments();
  const browserType = useBrowserType();
  const { isSidebarOpen } = useAdminSidebar();

  if (browserType === 'mobile' && (segments.at(-1) === '[conversationId]' || isSidebarOpen)) return <BackButton />;

  return (
    <Text fontStyle="bold" size="m" style={styles.logo}>
      {browserType === 'desktop' ? 'almond ' : ''}
      <Text fontStyle="bold" size="m" style={styles.admin}>
        {'(admin)'}
      </Text>
    </Text>
  );
};
