import { Button } from '@almond/ui';

import type { ButtonProps } from '@almond/ui';
import type { FC } from 'react';

export type SidebarButtonProps = Omit<ButtonProps, 'mode' | 'size' | 'fixedWidth'>;

export const SidebarButton: FC<SidebarButtonProps> = props => {
  const { children, ...restProps } = props;

  return (
    <Button {...restProps} size="s" fixedWidth={false}>
      {props.children}
    </Button>
  );
};
