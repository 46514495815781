/* eslint-disable global-require */
import { Appearance } from 'react-native';

export type Meditations = {
  plant1: number;
  plant2: number;
  universe2: number;
  universe3: number;
  error: number;
};

export type Images = Meditations;

export const lightImages: Images = {
  plant1: require('./meditations/plant1.webp'),
  plant2: require('./meditations/plant2.webp'),
  universe2: require('./meditations/universe2.webp'),
  universe3: require('./meditations/universe3.webp'),
  error: require('./meditations/error.webp'),
};

// We don't support multiple themes now, but it'll be possible in the future.
export const darkImages: Images = lightImages;

export const images: Images = Appearance.getColorScheme() === 'dark' ? darkImages : lightImages;
