import { View } from 'react-native';

import { Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useSegments } from 'expo-router';
import { useChatContext } from 'stream-chat-react';

import { useChannelData } from '../../hooks';
import { LeaveChannelButton } from './LeaveChannelButton';
import { MarkAsUnreadButton } from './MarkAsUnreadButton';
import { StatusToggleButton } from './StatusToggleButton';

import { themedStyles } from './styles';

type ChannelHeaderProps = {
  showJoinInThread?: boolean;
};

export const ChannelHeader = (props: ChannelHeaderProps) => {
  const [styles] = useTheme(themedStyles);
  const { channel } = useChatContext();
  const isPatient = useSegments()[0] === '(patient)';
  const channelData = useChannelData(channel);
  const { isDesktop, isMobile } = useBrowserTypeMap();

  if (isPatient && isMobile) {
    return;
  }

  const title = (() => {
    if (isPatient) return 'You & Almond Care Team';
    if (!channelData) return 'Patient conversation';

    return `${channelData.name} (${channelData.birthday})`;
  })();

  return (
    <View style={styles.container} testID="Channel-ChannelHeader">
      <Text fontStyle="bold" size="m" style={styles.text}>
        {title}
      </Text>
      {isDesktop && (
        <>
          <MarkAsUnreadButton />
          <StatusToggleButton />
        </>
      )}
      {props.showJoinInThread && <LeaveChannelButton />}
    </View>
  );
};
