import React from 'react';
import { View } from 'react-native';

import { useBrowserTypeMap, useTheme } from '@almond/ui';
import { Slot, useSegments } from 'expo-router';

import { ChannelList } from '~/modules/messaging';

import { themedStyles } from './styles';

const ConversationsLayout: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const { isDesktop } = useBrowserTypeMap();
  const segments = useSegments();

  const leftSidebarStyle = (() => {
    if (isDesktop) {
      return undefined;
    }

    if (segments.at(-1) === '[conversationId]') return styles.leftSidebarMobileHidden;

    return styles.leftSidebarMobile;
  })();

  return (
    <View style={styles.container}>
      <View style={[styles.leftSidebar, leftSidebarStyle]}>
        <ChannelList />
      </View>
      <Slot />
    </View>
  );
};

export default ConversationsLayout;
