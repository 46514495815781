import React from 'react';

import { IconButton } from '@almond/ui';
import { useRouter } from 'expo-router';

import { usePatientSidebar, useShouldDisplayBackButton } from '../../hooks';

export const BackButton: React.FC = () => {
  const { isSidebarOpen, toggleSidebar } = usePatientSidebar();
  const shouldDisplayBackButton = useShouldDisplayBackButton();
  const router = useRouter();

  if (!isSidebarOpen && !shouldDisplayBackButton) return null;

  const handleBack = router.canGoBack() ? router.back : () => router.push('/');

  return (
    <IconButton
      source="arrow-back"
      onPress={isSidebarOpen ? toggleSidebar : handleBack}
      color="text"
      testID="Back"
      accessibilityLabel="Previous Screen"
    />
  );
};
