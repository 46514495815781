import './channel-overrides.css';

import { init, SearchIndex } from 'emoji-mart';
import { Channel } from 'stream-chat-react';

import { useMarkAsRead } from '../../hooks';
import { streamColors } from '../../styles';
import { Attachment } from '../Attachment';
import { FileUploadIcon, SendButton } from './icons';
import { imageAttachmentSizeHandler } from './imageAttachmentSizeHandler';
import { MessageSimple } from './MessageSimple';

import type { Role } from '~/modules/auth/types';
import type { PropsWithChildren } from 'react';
import type { ChannelProps } from 'stream-chat-react';

type AlmondChannelProps = PropsWithChildren<ChannelProps & { role: Role }>;

// Include class with role type, so we can hide things for the patient that we
// don't hide for the provider
// NOTE - this hiding is just with CSS - a knowledgeable patient could still access
// hidden items. Do not hide important information using this method.

const initEmojis = async () => {
  const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data');
  const data = await response.json();

  init({ data });
};

initEmojis();

export const AlmondChannel: React.FC<AlmondChannelProps> = props => {
  const { channel } = props;

  useMarkAsRead(channel);

  return (
    <Channel
      {...props}
      Message={MessageSimple}
      FileUploadIcon={FileUploadIcon}
      SendButton={SendButton}
      Attachment={Attachment}
      imageAttachmentSizeHandler={imageAttachmentSizeHandler}
      // Hiding default TypingIndicator to display it above MessageInput
      TypingIndicator={() => null}
      emojiSearchIndex={SearchIndex}
    >
      <div className={`almond-${props.role}`} style={{ ...streamColors, display: 'flex', width: '100%' }}>
        {props.children}
      </div>
    </Channel>
  );
};

export { AlmondChannel as Channel };
