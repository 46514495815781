import { useCallback, useEffect } from 'react';

import { useAlmondApiMutation } from '~/modules/api';

import { useUnreadMessages } from '../components/UnreadMessagesProvider';
import { getCareguideIds } from '../utils';
import { useMarkAsReadForProvider } from './useMarkAsReadForProvider';

import type { Channel } from 'stream-chat';

export const useMarkAsRead = (channel: Channel | undefined) => {
  const { trigger } = useAlmondApiMutation(
    'post',
    channel ? `/messaging/channels/${channel.data?.id as '{channel_id}'}/read/` : null
  );
  const { deleteUnreadMessages } = useUnreadMessages();

  const markAsRead = useCallback(async () => {
    if (
      !channel ||
      !channel.id ||
      channel.state.membership.user?.role !== 'careguide' ||
      !channel.state.messages.length
    )
      return;

    const userIds = getCareguideIds(channel);

    await trigger({ userIds });
    deleteUnreadMessages(channel.id);
  }, [channel, deleteUnreadMessages, trigger]);

  useEffect(() => {
    if (!channel) return;

    markAsRead();

    const newMessageListener = channel.on('message.new', () => {
      // Don't mark as read if the tab is in the background
      if (document.hasFocus()) {
        markAsRead();
      }
    });

    return () => {
      newMessageListener.unsubscribe();
    };
  }, [channel, markAsRead]);

  useMarkAsReadForProvider(channel);
};
