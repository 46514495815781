// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./(_root)/_layout.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(_root)/_layout.tsx"); } },
  "./(_root)/index.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(_root)/index.tsx"); } },
  "./(admin)/(conversations)/[conversationId]/index.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(admin)/(conversations)/[conversationId]/index.tsx"); } },
  "./(admin)/(conversations)/_layout.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(admin)/(conversations)/_layout.tsx"); } },
  "./(admin)/(conversations)/index.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(admin)/(conversations)/index.tsx"); } },
  "./(admin)/(conversations)/styles.ts": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(admin)/(conversations)/styles.ts"); } },
  "./(admin)/_layout.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(admin)/_layout.tsx"); } },
  "./(admin)/elation/[elationId].tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(admin)/elation/[elationId].tsx"); } },
  "./(admin)/elation/index.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(admin)/elation/index.tsx"); } },
  "./(admin)/elation/styles.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(admin)/elation/styles.tsx"); } },
  "./(patient)/+not-found.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(patient)/+not-found.tsx"); } },
  "./(patient)/_layout.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(patient)/_layout.tsx"); } },
  "./(patient)/documents/[documentId]/index.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(patient)/documents/[documentId]/index.tsx"); } },
  "./(patient)/documents/[documentId]/styles.ts": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(patient)/documents/[documentId]/styles.ts"); } },
  "./(patient)/health-records/index.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(patient)/health-records/index.tsx"); } },
  "./(patient)/health-records/styles.ts": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(patient)/health-records/styles.ts"); } },
  "./(patient)/index.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(patient)/index.tsx"); } },
  "./(patient)/messages/index.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(patient)/messages/index.tsx"); } },
  "./(patient)/messages/styles.ts": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(patient)/messages/styles.ts"); } },
  "./(patient)/settings/index.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(patient)/settings/index.tsx"); } },
  "./(patient)/settings/styles.ts": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(patient)/settings/styles.ts"); } },
  "./(patient)/styles.ts": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/(patient)/styles.ts"); } },
  "./+not-found.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/+not-found.tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/_layout.tsx"); } },
  "./styles.ts": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/artemis/src/app/styles.ts"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;