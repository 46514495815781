import React from 'react';
import { View } from 'react-native';

import { MaterialIcon, sizes, Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

export type NameProps = {
  name?: string;
  isChannelPinned?: boolean;
};

export const Name: React.FC<NameProps> = props => {
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.nameContainer}>
      {props.isChannelPinned && (
        <View style={styles.pinContainer}>
          <MaterialIcon source="keep" size={sizes.FONT_SIZE_M} color="placeholder" testID="ChannelList-Item-Pinned" />
        </View>
      )}
      <Text numberOfLines={1} family="SystemFont" fontStyle="medium" size="m">
        {props.name}
      </Text>
    </View>
  );
};
