import React from 'react';
import { Pressable, View } from 'react-native';

import { Text, useBrowserType, useTheme } from '@almond/ui';
import dayjs from 'dayjs';

import { formatFileName } from '../../utils';
import { FileThumbnail } from '../FileThumbnail';
import { MutateButtons } from './MutateButtons';

import { themedStyles } from './styles';

import type { ExtendedDocumentOut } from '../../types';
import type { MutateButtonsProps } from './MutateButtons';

export type ModalListPreviewProps = {
  item: ExtendedDocumentOut;
  displayUploadDate?: boolean;
  onOpenPreview: (item: ExtendedDocumentOut) => void;
  testID?: string;
} & MutateButtonsProps;

export const ModalListPreview: React.FC<ModalListPreviewProps> = props => {
  const { item, onOpenPreview, displayUploadDate, testID, ...mutateProps } = props;
  const [styles] = useTheme(themedStyles);
  const browserType = useBrowserType();
  const isMobile = browserType === 'mobile';

  const formattedUploadDate = dayjs(item.uploadedAt).format('MM.DD.YY [at] h:mm A');

  return (
    <View style={[styles.container, !isMobile && styles.containerBig]} testID={testID}>
      <Pressable role="button" onPress={() => onOpenPreview(item)} style={styles.previewButton}>
        <FileThumbnail
          fileLocation={item.fileLocation}
          filename={item.filename}
          style={[styles.thumbnail, browserType === 'mobile' && styles.mobileThumbnail]}
        />

        <View style={styles.body}>
          <Text
            style={styles.fileName}
            fontStyle={isMobile ? 'black' : 'bold'}
            size={isMobile ? 'm' : 'xl'}
            numberOfLines={1}
          >
            {formatFileName(item.filename)}
          </Text>
          <Text style={styles.fileSize} size={isMobile ? 's' : 'm'} numberOfLines={2}>
            {displayUploadDate ? `${formattedUploadDate} | ` : ''}
            {item.uploadedByName || ''}
          </Text>
        </View>
      </Pressable>
      <MutateButtons item={item} {...mutateProps} />
    </View>
  );
};
