import React, { useEffect } from 'react';
import { View } from 'react-native';

import { ScrollView, useAdaptive, useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

import { ErrorContainer } from '~/modules/logging';
import { PatientVisits, Welcome } from '~/modules/patient';
import { Referral } from '~/modules/referral';
import { BackgroundImage, useRerender } from '~/modules/ui';
import { isCurrentUserIncomplete } from '~/modules/user';

import { themedStyles } from './styles';

export { ErrorContainer as ErrorBoundary };

const PatientGroup: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const containerStyle = useAdaptive(styles.containerDesktop, styles.containerMobile);
  const { client } = useChatContext();
  const { user } = client;
  const rerender = useRerender();

  useEffect(() => {
    if (user && Object.keys(user).length > 1) return;

    const listener = client.on('health.check', () => {
      // Sometimes the user from the client includes only 1 field (id).
      // As soon as this event is fired, we need to rerender the component to have the correct user.
      rerender();
    });

    return () => {
      listener.unsubscribe();
    };
  }, [client, rerender, user]);

  return (
    <View style={styles.background}>
      <BackgroundImage type="desktop" />

      <ScrollView contentContainerStyle={[styles.container, containerStyle]}>
        <Welcome />
        <PatientVisits />
        {!isCurrentUserIncomplete(user?.role) && <Referral />}
      </ScrollView>
    </View>
  );
};

export default PatientGroup;
