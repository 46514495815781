.almond__edit-send-container {
  display: flex;
  flex-direction: row-reverse;
  gap: 12px;

  margin-top: 12px;
  margin-left: auto;
}

.str-chat__edit-title {
  margin-bottom: 12px;
}

.str-chat__message-input {
  padding: 0;
}

.str-chat__message-input-inner {
  padding: var(--str-chat__spacing-2) var(--str-chat__spacing-2) var(--str-chat__spacing-2)
    calc(var(--str-chat__spacing-px) * 2);
  align-items: center;
}


.almond__character-count-container {
  position: absolute;
  bottom: 19px;
  right: 56px;
}

.almond__character-count {
  font-size: 10px;
}
