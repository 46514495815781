import { StyleSheet } from 'react-native';

import { getColorWithOpacity, getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    ...getShadow(),
    backgroundColor: theme.background,
    borderRadius: sizes.SPACING_XS,
    rowGap: sizes.SPACING_S,
    overflow: 'hidden',
  },

  title: {
    marginTop: sizes.SPACING_L,
    marginHorizontal: sizes.SPACING_M,
  },

  content: {
    flex: 1,
    paddingHorizontal: sizes.SPACING_M,
    paddingVertical: sizes.SPACING_L,
  },

  image: {
    width: 240,
    height: 200,
    right: -80,
    position: 'absolute',
    transform: [{ rotateY: '180deg' }],
  },

  seeMoreButton: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: getColorWithOpacity(theme.skeletonLoader, 0.5),
    alignItems: 'center',
    justifyContent: 'center',
    padding: sizes.SPACING_S,
    flexDirection: 'row',
  },
}));
