import React from 'react';

import { Accordion, SENTRY_UNMASK, useTheme } from '@almond/ui';
import { cypressUtilities, useToggle } from '@almond/utils';
import { useChatContext } from 'stream-chat-react';

import { useAlmondApiQuery } from '~/modules/api';
import { EditDemographicModal, EditRightAction } from '~/modules/profile';
import { AccordionTitle, Sidebar, SidebarButton } from '~/modules/ui';
import { useBookUrl, useCurrentUser } from '~/modules/user';

import { useListenForNewAttachments, useToggleMember } from '../../hooks';
import { getChannelPatient } from '../../utils';
import { ConversationMembers } from '../ConversationMembers';
import { HealthRecords } from '../HealthRecords';
import { PatientInformation } from '../PatientInformation';
import { PatientVisits } from '../PatientVisits';
import { SharedMedia } from '../SharedMedia';

import { themedStyles } from './styles';

export const AdminSidebar = () => {
  const [styles] = useTheme(themedStyles);
  const { channel, client } = useChatContext();
  const currentUserRole = client.user?.role;
  const patientUuid = getChannelPatient(channel)?.patient_uuid as string | undefined;
  const { data, error, mutate } = useAlmondApiQuery(
    patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/` : null
  );
  const bookUrl = useBookUrl({ isAdmin: true, isNewMember: false, patientUuid, email: data?.profile.email });
  const labBookUrl = useBookUrl({ isAdmin: true, isNewMember: false, patientUuid, email: data?.profile.email }, 'lab');
  const currentUser = useCurrentUser();
  const { buttonTitle, buttonType, toggleMember } = useToggleMember({
    user: { id: currentUser?.streamId, role: currentUserRole },
    channel,
    streamId: currentUser?.streamId,
    joinTitle: 'Join Conversation',
    leaveTitle: 'Leave Conversation',
  });
  const [isVisible, toggleIsVisible] = useToggle(false);

  useListenForNewAttachments();

  return (
    <Sidebar
      footer={
        buttonTitle && (
          <SidebarButton type={buttonType} onPress={toggleMember}>
            {buttonTitle}
          </SidebarButton>
        )
      }
    >
      <Accordion
        initiallyOpen
        title={<AccordionTitle>{'Patient Information'}</AccordionTitle>}
        rightAction={<EditRightAction onPress={toggleIsVisible} />}
      >
        <PatientInformation canViewElation data={data} error={error} />
        {data && (
          <>
            <SidebarButton
              style={[styles.button, SENTRY_UNMASK]}
              href={bookUrl}
              // Cypress does not support opening a new tab.
              hrefAttrs={cypressUtilities.isCypressRunning() ? undefined : { target: '_blank' }}
            >
              {'Book a Visit'}
            </SidebarButton>
            <SidebarButton
              style={[styles.button, SENTRY_UNMASK]}
              href={labBookUrl}
              // Cypress does not support opening a new tab.
              hrefAttrs={cypressUtilities.isCypressRunning() ? undefined : { target: '_blank' }}
            >
              {'Book a Lab Visit'}
            </SidebarButton>
          </>
        )}
      </Accordion>
      <Accordion title={<AccordionTitle>{'Conversation Members'}</AccordionTitle>}>
        <ConversationMembers canInviteUsers />
      </Accordion>
      <Accordion title={<AccordionTitle>{'Patient Visits'}</AccordionTitle>}>
        <PatientVisits revalidateOnFocus />
      </Accordion>
      <Accordion title={<AccordionTitle>Shared Media</AccordionTitle>}>
        <SharedMedia editable />
      </Accordion>
      <Accordion title={<AccordionTitle>Health Records</AccordionTitle>}>
        <HealthRecords />
      </Accordion>
      {data && patientUuid && (
        <EditDemographicModal
          isVisible={isVisible}
          onRequestClose={toggleIsVisible}
          showClose
          patient={data}
          mutatePatient={mutate}
          isAdmin
          patientUuid={patientUuid}
        />
      )}
    </Sidebar>
  );
};
