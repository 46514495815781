import React from 'react';

import { ActivityIndicator, sizes } from '@almond/ui';
import { useGlobalSearchParams } from 'expo-router';

import { useAlmondApiQuery } from '~/modules/api';
import { Conversation, useChannel } from '~/modules/messaging';
import { Error } from '~/modules/ui';

import type { ElationDetailsParams } from '~/types';

const ElationIdChannel: React.FC = () => {
  const { elationId } = useGlobalSearchParams<ElationDetailsParams>();

  const { data, error, isLoading } = useAlmondApiQuery(
    elationId ? `/patients/by_elation/${elationId as '{elation_patient_id}'}` : null
  );

  const conversationId = data?.authId ? data.authId.replace('auth0|', '') : undefined;

  const { isReady } = useChannel(conversationId, !!conversationId);

  if (isLoading || !isReady) return <ActivityIndicator size={sizes.SPACING_M} />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  return <Conversation showJoinInThread />;
};

export default ElationIdChannel;
