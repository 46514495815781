import { useCallback, useEffect, useMemo } from 'react';

import { areArraysEquivalent, uniqueBy, useEvent } from '@almond/utils';
import { useChatContext } from 'stream-chat-react';

import { logErrorToSentry } from '~/modules/logging';

import { streamChannelSort } from '../../utils';
import { useCachedChannels } from '../useCachedChannels';
import { LIMIT, REFRESH_INTERVAL, sortConfig } from './config';
import { useListenToChannelUpdateEvents } from './useListenToChannelUpdateEvents';
import { useStreamFilter } from './useStreamFilter';

import type { Filter } from '../../types';
import type { ChannelFilters } from 'stream-chat';

export const useChannelsToDisplay = (filter: Filter | undefined, search: string) => {
  const { client } = useChatContext();
  const filters = useStreamFilter(filter, search);
  const filterString = useMemo(() => JSON.stringify(filters), [filters]);

  const {
    channels,
    isLoading,
    error,
    hasNextPage,
    setChannels,
    setIsLoading,
    setError,
    setHasNextPage,
    lastRefreshedAt,
  } = useCachedChannels(filterString);

  useListenToChannelUpdateEvents(channels, setChannels, filters);

  const loadChannels = useEvent(
    async (options: { filters: ChannelFilters | undefined; offset: number; integrityCheck?: boolean }) => {
      const { filters: filtersToLoad, offset, integrityCheck = false } = options;

      if (!filtersToLoad) {
        return;
      }

      setIsLoading(true);
      setError(null);
      try {
        let limit = LIMIT;

        if (integrityCheck && offset === 0) {
          // If we're checking for integrity, load multiple pages
          limit = Math.max(channels.length, LIMIT);
        }

        const loadedChannels = await client.queryChannels(filtersToLoad, sortConfig, { offset, limit });

        setChannels(!offset, prevChannels => {
          const newChannels = streamChannelSort(
            prevChannels ? uniqueBy([...prevChannels.slice(0, offset), ...loadedChannels], 'cid') : loadedChannels,
            sortConfig
          );

          if (integrityCheck && offset === 0) {
            if (
              areArraysEquivalent(newChannels, prevChannels.slice(0, newChannels.length), (a, b) => a.cid === b.cid)
            ) {
              // Arrays are equivalent (up to the limit), so keep the original list.
              // This prevents any extra pagination past the 30 channel limit per API
              // request from being un-loaded from the list.
              return prevChannels;
            }

            // Existing channel list somehow got out of sync with the server. Replace
            // list with up to date data
            logErrorToSentry(new Error('Channels are not equivalent'), {
              filters: JSON.stringify(filtersToLoad),
              newChannels: newChannels.map(c => c.cid).join(', '),
              prevChannels: prevChannels.map(c => c.cid).join(', '),
            });
          }

          return newChannels;
        });
        setHasNextPage(loadedChannels.length === LIMIT);
      } catch (e) {
        setError(e as Error);
        setHasNextPage(false);
      } finally {
        setIsLoading(false);
      }
    }
  );

  const loadMore = useCallback(() => {
    loadChannels({ filters, offset: channels.length || 0 });
  }, [channels.length, loadChannels, filters]);

  const refresh = useCallback(() => {
    loadChannels({ filters, offset: 0 });
  }, [loadChannels, filters]);

  // Re-run anytime the filter (or client) changes
  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    // Current list was last refreshed at `lastRefreshedAt`. Set a timer to
    // refresh the list once it's been more than 5 minutes since the last refresh
    if (lastRefreshedAt) {
      const timeSinceLastRefresh = new Date().getTime() - lastRefreshedAt.getTime();
      const timer = setTimeout(() => {
        // TODO identify if the list actually changed

        // loadChannels(0) will cause lastRefreshedAt to be updated, causing
        // a new timer to be set.
        loadChannels({ filters, offset: 0, integrityCheck: true });
      }, REFRESH_INTERVAL - timeSinceLastRefresh);

      return () => clearTimeout(timer);
    }

    return () => undefined;
  }, [filters, lastRefreshedAt, loadChannels]);

  return {
    channels,
    isLoading,
    error,
    loadMore,
    hasNextPage,
    refresh,
  };
};
