import { View } from 'react-native';

import { Button, PulseProvider, Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import { ChannelPreview } from 'stream-chat-react';

import { useChannelsToDisplay } from '../../hooks';
import cssModuleStyles from './ChannelListItems.module.css';
import { LoadMoreButton } from './LoadMoreButton';
import { LoadingPreview, Preview } from './Preview';

import { themedStyles } from './styles';

import type { Filter } from '../../types';
import type { Channel } from 'stream-chat';

type ChannelListItemsProps = {
  filter: Filter | undefined;
  search: string;
  openConversationMenu?: (channel: Channel) => void;
  renderAbove: (options: { isLoading: boolean }) => React.ReactNode;
};

export const ChannelListItems = (props: ChannelListItemsProps) => {
  const { filter, search, openConversationMenu, renderAbove } = props;
  const [styles] = useTheme(themedStyles);
  const { isMobile } = useBrowserTypeMap();
  const { channels, isLoading, error, loadMore, hasNextPage, refresh } = useChannelsToDisplay(filter, search);

  const showLoading = isLoading && !channels.length;

  if (showLoading) {
    return (
      <>
        {renderAbove({ isLoading })}
        <View style={[styles.channelListItemsContainer, styles.channelListItems]}>
          <PulseProvider duration={1000}>
            {Array(3)
              .fill(null)
              .map((_, i) => (
                <LoadingPreview key={i} />
              ))}
          </PulseProvider>
        </View>
      </>
    );
  }

  return (
    <>
      {renderAbove({ isLoading })}
      <View style={styles.channelListItemsContainer}>
        {error && (
          <details className={cssModuleStyles.details}>
            <summary className={cssModuleStyles.summary}>
              <div className={cssModuleStyles.summaryContainer}>
                <Text style={styles.channelListErrorText}>Error loading channels</Text>
                <Button mode="text" size="xs" fixedWidth={false} onPress={refresh}>
                  Retry
                </Button>
              </div>
            </summary>
            <Text style={styles.channelListErrorText}>{error.message}</Text>
          </details>
        )}
        {!channels.length && (
          <View style={styles.channelListEmpty}>
            <Text style={styles.channelListEmptyText} size="m">
              {search
                ? 'No conversations match your search.'
                : // eslint-disable-next-line max-len
                  'Any conversation you are a member of that is unread, marked as unread and/or not manually resolved will appear here.'}
            </Text>
          </View>
        )}
        {!!channels.length && (
          <View style={styles.channelListItems} role="list">
            {channels.map(channel => (
              <View key={channel.cid} role="listitem">
                <ChannelPreview
                  channel={channel}
                  Preview={previewProps => (
                    <Preview
                      {...previewProps}
                      isSearching={!!search}
                      onLongPress={isMobile ? openConversationMenu : undefined}
                    />
                  )}
                />
              </View>
            ))}
            {hasNextPage && <LoadMoreButton onClick={loadMore} isLoading={isLoading} />}
          </View>
        )}
      </View>
    </>
  );
};
