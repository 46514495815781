import React from 'react';
import { View } from 'react-native';

import { ActivityIndicator, Button, Text, useAdaptive, useTheme } from '@almond/ui';
import { useClipboard } from '@almond/utils';

import { logErrorToSentry } from '~/modules/logging';
import { env } from '~env';

import { themedStyles } from './styles';

export type ReferralProps = {
  referralCode?: string;
};

export const Referral = (props: ReferralProps) => {
  const [styles] = useTheme(themedStyles);
  const { copy, isCopied } = useClipboard();
  const referralLink = props.referralCode ? `${env.BOOK_URL}/refer/${props.referralCode}` : undefined;
  const referralSize = useAdaptive('base', 's');

  const handleShareLinkPress = async () => {
    try {
      await navigator.share({
        url: referralLink,
        title: '$25 off your Almond Membership',
        text: 'I want you to try Almond! A new kind of ObGyn care!',
      });
    } catch (error: any) {
      if (error?.name !== 'AbortError') {
        logErrorToSentry(error);
      }
    }
  };

  if (!referralLink) return <ActivityIndicator />;

  return (
    <View>
      <Text style={styles.referral} size={referralSize}>
        {referralLink}
      </Text>
      <View style={styles.buttonsContainer}>
        <View>
          <Button
            type="lightAccent"
            onPress={() => copy(referralLink)}
            isSquared
            style={styles.linkButton}
            fontStyle="bold"
            testID="CopyLink"
          >
            {'Copy link'}
          </Button>
          {isCopied && (
            <Text style={styles.linkText} testID="Copied">
              {'Copied!🎉'}
            </Text>
          )}
        </View>
        {'share' in navigator && (
          <Button isSquared onPress={handleShareLinkPress} style={styles.linkButton} fontStyle="bold">
            {'Share link'}
          </Button>
        )}
      </View>
    </View>
  );
};
