import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  error: {
    width: '100%',
    color: theme.error,
    textAlign: 'center',
  },
}));
