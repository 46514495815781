import React from 'react';
import { View } from 'react-native';

import { useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

// Prevent circular dependency
import { useUnreadCount } from '~/modules/messaging/hooks';
import { isCurrentUserIncomplete } from '~/modules/user';

import { Tab } from './Tab';

import { themedStyles } from './styles';

import type { TabProps } from './Tab';

export const Tabs: React.FC = () => {
  const [styles] = useTheme(themedStyles);
  const unreadCount = useUnreadCount();
  const { client } = useChatContext();
  const { user } = client;
  // TODO: Add translations.
  const tabs: TabProps[] = [
    { title: 'Get Care', href: '/(patient)' },
    { title: 'Health Records', href: '/(patient)/health-records' },
    {
      title: 'Messages',
      href: '/(patient)/messages',
      indicator: unreadCount > 0,
      isDisabled: !!user?.role && isCurrentUserIncomplete(user.role),
    },
  ];

  return (
    <View style={styles.tabs}>
      {tabs.map(tab => (
        <Tab key={tab.href} {...tab} />
      ))}
    </View>
  );
};
