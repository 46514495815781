.str-chat {
  --str-chat__border-radius-md: var(--str-chat__spacing-4);
}

.str-chat__message-bubble {
  background-color: transparent !important;
  max-width: unset !important;
}

.str-chat__main-panel-inner {
  width: 100%;
}

/************
 * These overrides position the author text above the messages,
 * and positions "my" avatar correctly.
 ***********/

.str-chat__message-author {
  grid-area: author;
}

/* Chats on the left side of the window */
.str-chat__message.str-chat__message--other {
  grid-template-areas:
    '.      author             author'
    'avatar message            message'
    '.      replies            replies'
    '.      translation-notice translation-notice'
    '.      metadata           edited';
  grid-template-columns: auto min-content 1fr;
}

/* Chats on the right side of the window */
.str-chat__message.str-chat__message--me {
  grid-template-areas:
    'author             author             .'
    'message            message            avatar'
    'replies            replies            .'
    'translation-notice translation-notice .'
    'edited             metadata           .';
  grid-template-columns: 1fr min-content auto;
  column-gap: var(--str-chat__spacing-2);
}

.str-chat__message.str-chat__message--me > .str-chat__message-sender-avatar {
  display: block;
}

.str-chat__message-simple-status-number {
  word-break: keep-all;
}

.str-chat__message-edited {
  grid-area: edited;
  color: var(--str-chat__message-edited-text);
  font-size: 14px;
  align-self: center;
}

/* Hide read receipts from patients */
.almond-patient .str-chat__message-status {
  display: none;
}

/* Tweak vertical message padding to match designs */
.str-chat__li--middle .str-chat__message,
.str-chat__li--top .str-chat__message {
  margin-block-end: var(--str-chat__spacing-1);
}

/***********
 * Position sender name above message, moved over the same width
 * as the border radius of the message it's next to.
 ***********/
.str-chat__message--me .str-chat__message-simple-name {
  margin-right: var(--str-chat__message-bubble-border-radius);
}

.str-chat__message--other .str-chat__message-simple-name {
  margin-left: var(--str-chat__message-bubble-border-radius);
}

/***********
 * Only show the sender name once per grouped set of messages
 ***********/
.str-chat__li--bottom .str-chat__message .str-chat__message-author,
.str-chat__li--middle .str-chat__message .str-chat__message-author {
  display: none;
}

/* No dividing line with the centered timestamp */
.str-chat__date-separator-line {
  display: none;
}

.str-chat__date-separator {
  display: flex;
  justify-content: center;
}

/**************
 * Message Input
 **************/
.str-chat__message-textarea::placeholder {
  color: var(--str-chat__placeholder-color);
}

.str-chat__message-input-inner {
  gap: var(--str-chat__spacing-1_5);
}

.str-chat__message-input
  .str-chat__message-input-inner
  .str-chat__message-textarea-container
  .str-chat__message-textarea-with-emoji-picker {
  padding: var(--str-chat__spacing-1) var(--str-chat__spacing-4);
  padding-right: var(--str-chat__spacing-6);
}

.str-chat__message-input
  .str-chat__message-input-inner
  .str-chat__message-textarea-container-error {
  border: 1px solid var(--str-chat__primary-red-color);
  border-radius: 12px;
}

.str-chat__message-input .str-chat__message-input-inner .str-chat__file-input-container,
.str-chat__message-input .str-chat__send-button {
  height: calc(var(--str-chat__spacing-px) * 38);
  width: auto;
}

/**************
 * Reply button
 **************/
.str-chat__ul:not(.str-chat__message-options-in-bubble) .str-chat__li .str-chat__message-options {
  display: flex;
  visibility: hidden;
}

.str-chat__ul:not(.str-chat__message-options-in-bubble) .str-chat__li:hover .str-chat__message-options {
  visibility: visible;
}

/* There's definitely something weird happening with focus. Tabbing through messages intermittently
   brings focus to <body>. Might be stream doing that, will need to troubleshoot at some point in the
   future
  */
.str-chat__ul:not(.str-chat__message-options-in-bubble) .str-chat__li:has(:focus-visible) .str-chat__message-options {
  visibility: visible;
}

.str-chat__message .str-chat__message-inner .str-chat__message-options .str-chat__message-actions-box-button {
  aspect-ratio: 1;
  padding: var(--str-chat__spacing-2);
}

.str-chat__modal--open .str-chat__modal__inner {
  width: 80%;
}

.str-chat__message.str-chat__message--has-attachment {
  --str-chat__message-max-width: calc(var(--str-chat__spacing-px) * 480);
}

/* OTHER MESSAGES */

.str-chat__message--other .str-chat__message-inner {
  grid-template-columns: 3fr 1fr !important;
  margin-inline-end: 0 !important;
}

.str-chat__message--other .str-chat__message-bubble {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-self: start;
}

.str-chat__message--other .str-chat__message-text {
  background-color: var(--str-chat__secondary-surface-color);
  border-radius: var(--str-chat__border-radius-md) var(--str-chat__border-radius-md) var(--str-chat__border-radius-md) 0;
}

.str-chat__message--other .attachment-container + .str-chat__message-text,
.str-chat__li--middle .str-chat__message--other .str-chat__message-text,
.str-chat__li--bottom .str-chat__message--other .str-chat__message-text {
  border-radius: 0 var(--str-chat__border-radius-md) var(--str-chat__border-radius-md) 0;
}

/* PATIENT MESSAGES */

.str-chat__message--patient .str-chat__message-text {
  background-color: var(--str-chat__admin-other-patient-surface-background);
}

/* ME MESSAGES */

.str-chat__message--me .str-chat__message-inner {
  grid-template-columns: 1fr 3fr !important;
  margin-inline-start: 0 !important;
}

.str-chat__message--me .str-chat__message-options {
  justify-self: end;
}

.str-chat__message--me .str-chat__message-bubble {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-self: end;
}

.str-chat__message--me .str-chat__message-text {
  background-color: var(--str-chat__primary-surface-color);
  border-radius: var(--str-chat__border-radius-md) var(--str-chat__border-radius-md) 0 var(--str-chat__border-radius-md);
}

.str-chat__li--middle .str-chat__message--me .str-chat__message-text,
.str-chat__li--bottom .str-chat__message--me .str-chat__message-text {
  border-radius: var(--str-chat__border-radius-md) 0 0 var(--str-chat__border-radius-md);
}

.str-chat__message--me .attachment-container + .str-chat__message-text {
  border-radius: var(--str-chat__border-radius-md) 0 0 var(--str-chat__border-radius-md);
}
