import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { ensureConversationMemberSchema } from '@almond/extension-utils';
import { ActivityIndicator, Text, useBrowserType, useTheme } from '@almond/ui';
import { useChatContext } from 'stream-chat-react';

import { useExtensionMessageListener } from '~/modules/extension';
import { useAdminSidebar } from '~/modules/routing';
import { SidebarButton } from '~/modules/ui';
import { useCurrentUser } from '~/modules/user';

import { useConversationMembers, useToggleMember } from '../../hooks';
import { AdminSidebar } from '../AdminSidebar';
import { Channel } from '../Channel';
import { ChannelHeader } from '../ChannelHeader';
import { DeleteMessageProvider } from '../DeleteMessageProvider';
import { MessageInput } from '../MessageInput';
import { MessageList } from '../MessageList';

import { themedStyles } from './styles';

type ConversationProps = {
  showJoinInThread?: boolean;
};

export const Conversation = (props: ConversationProps) => {
  const [styles] = useTheme(themedStyles);
  const { channel, client } = useChatContext();
  const conversationMembers = useConversationMembers(channel);
  const currentUserIsChannelMember = conversationMembers.some(member => member.isMe);
  const browserType = useBrowserType();
  const { isSidebarOpen } = useAdminSidebar();
  const currentUser = useCurrentUser();
  const currentUserRole = client.user?.role;

  const { buttonTitle, buttonType, toggleMember, isChannelMember } = useToggleMember({
    user: { id: currentUser?.streamId, role: currentUserRole },
    channel,
    streamId: currentUser?.streamId,
  });

  useExtensionMessageListener(ensureConversationMemberSchema, async () => {
    // Join the conversation, if not already a member
    if (isChannelMember === false) {
      await toggleMember();
    }
  });

  const content = useMemo(() => {
    // We don`t want to render content until conversationMembers are loaded
    if (!conversationMembers.length) {
      return (
        <View style={styles.notFoundContainer}>
          <ActivityIndicator />
        </View>
      );
    }

    if (channel) {
      const conversation = (
        <>
          <ChannelHeader showJoinInThread={props.showJoinInThread} />
          {channel && (
            <Channel channel={channel} role="admin">
              <View style={styles.channelContent}>
                <View style={StyleSheet.absoluteFill}>
                  <DeleteMessageProvider>
                    <MessageList />
                  </DeleteMessageProvider>
                  {currentUserIsChannelMember && <MessageInput />}
                  {!currentUserIsChannelMember && isChannelMember === false && props.showJoinInThread && (
                    <SidebarButton type={buttonType} onPress={toggleMember} style={styles.joinButton}>
                      {buttonTitle}
                    </SidebarButton>
                  )}
                </View>
              </View>
            </Channel>
          )}
        </>
      );

      return <View style={styles.channelContainer}>{isSidebarOpen ? <AdminSidebar /> : conversation}</View>;
    }

    return (
      <View style={styles.notFoundContainer}>
        <Text fontStyle="bold" size="xl" style={styles.notFoundText}>
          {`That patient wasn't found`}
        </Text>
        <Text fontStyle="medium" size="m" style={styles.notFoundText}>
          {'Select a different patient or let the engineering team know'}
        </Text>
      </View>
    );
  }, [
    conversationMembers.length,
    channel,
    styles.notFoundContainer,
    styles.notFoundText,
    styles.channelContent,
    styles.joinButton,
    styles.channelContainer,
    props.showJoinInThread,
    currentUserIsChannelMember,
    isChannelMember,
    buttonType,
    toggleMember,
    buttonTitle,
    isSidebarOpen,
  ]);

  return (
    <View style={styles.wrapper}>
      {/*
       * Order reversed with flexDirection: row-reverse. So
       * clicking an image brings up the image viewer overlay
       * on top of the sidebar. If we aren't using the default
       * Stream image view overlay, we can switch this to "row"
       * and swap the order of the chat window and sidebar in
       * the DOM.
       */}
      {browserType === 'desktop' && <AdminSidebar />}
      {content}
    </View>
  );
};
