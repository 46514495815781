import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { Button, Text, useAdaptive, useFadeIn, useTheme } from '@almond/ui';
import { useToggle } from '@almond/utils';
import { useRouter } from 'expo-router';
import { useChatContext } from 'stream-chat-react';

import { useAlmondApiQuery } from '~/modules/api';
import { useUnreadCount } from '~/modules/messaging/hooks';
import { SendVerificationCodeModal, VerifyPhoneNumberModal } from '~/modules/profile';
import { isCurrentUserIncomplete, useBookUrl, useCurrentUser } from '~/modules/user';

import { themedStyles } from './styles';

const title = '✨ Dreams Do Come True';
const subtitle =
  // eslint-disable-next-line max-len
  'Receive and send text messages directly with the Care Team about sensitive medical and billing information. We promise no marketing messages :)';

export const Welcome = () => {
  const [styles] = useTheme(themedStyles);
  const currentUser = useCurrentUser();
  const { data, isLoading, mutate } = useAlmondApiQuery(
    currentUser?.patientUuid ? `/patients/${currentUser?.patientUuid as '{patient_uuid}'}/` : null
  );
  const [isLoadingInitial] = useState(isLoading);
  const fadeIn = useFadeIn(!(isLoadingInitial && isLoading));
  const titleSize = useAdaptive('xxxxl', 'xxxl');
  const bookUrl = useBookUrl({ isNewMember: false });
  const unreadCount = useUnreadCount();
  const router = useRouter();
  const { client } = useChatContext();
  const { user } = client;
  const [isSendCodeVisible, toggleIsSendCodeVisible] = useToggle(false);
  const [isVerifyVisible, toggleIsVerifyVisible] = useToggle(false);

  useEffect(() => {
    // Waiting for the current user and patient to be loaded.
    if (!user?.role || !data) {
      return;
    }

    const shouldShowModal =
      // Current user is complete.
      !isCurrentUserIncomplete(user.role) &&
      // The patient was created before the settings page milestone.
      (data.profile.smsOptInStatus == null ||
        // Or it was created after, but didn't verify his phone number successfully.
        (data.profile.smsOptInStatus === 'opted_in' && !data.profile.isSmsVerified));

    if (shouldShowModal) {
      toggleIsSendCodeVisible();
    }
  }, [data, toggleIsSendCodeVisible, user?.role]);

  return (
    <View style={styles.container}>
      <Text style={styles.title} size={titleSize} fontStyle="bold" family="PlayfairDisplay" animate={fadeIn}>
        {!isLoading && data && `Hi ${data.profile.preferredFirstName || data.profile.firstName}, how can we help?`}
        {!isLoading && !data && 'Hi, how can we help you?'}
      </Text>
      <Button href={bookUrl} size="m">
        {'Book a visit'}
      </Button>
      {!!user?.role && !isCurrentUserIncomplete(user.role) && (
        <Button
          style={unreadCount > 0 && styles.buttonAlert}
          onPress={() => router.push('/(patient)/messages')}
          size="m"
          type="secondary"
        >
          {unreadCount > 0 ? `View new messages (${unreadCount})` : 'Message Care Team'}
        </Button>
      )}

      {data && (
        <SendVerificationCodeModal
          title={title}
          subtitle={subtitle}
          isVisible={isSendCodeVisible}
          onRequestClose={toggleIsSendCodeVisible}
          patient={data}
          mutatePatient={mutate}
          onOptInPress={() => {
            toggleIsSendCodeVisible();
            toggleIsVerifyVisible();
          }}
          onUpdatePhonePress={() => {
            toggleIsSendCodeVisible();
            router.navigate('/(patient)/settings');
          }}
        />
      )}

      {data && (
        <VerifyPhoneNumberModal
          title={title}
          subtitle={subtitle}
          isVisible={isVerifyVisible}
          onRequestClose={toggleIsVerifyVisible}
          patient={data}
          mutatePatient={mutate}
          optOutOnRequestClose
          optOutOnError
          optInOnSuccess
          sendTextOnSuccess
        />
      )}
    </View>
  );
};
