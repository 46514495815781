import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@almond/ui';

import { LocalImage } from '~/modules/ui';

import { themedStyles } from './styles';

const ElationNoIdSpecified: React.FC = () => {
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.container}>
      <LocalImage source="plant2" resizeMode="contain" style={styles.image} />
      <Text style={styles.title} fontStyle="bold" size="xl">
        {'Please choose a patient chart to view patient conversation'}
      </Text>
    </View>
  );
};

export default ElationNoIdSpecified;
