import { useBrowserType } from '@almond/ui';
import { useSegments } from 'expo-router';

import { useSidebar } from '~/modules/ui';

export const useAdminSidebar = () => {
  const sidebar = useSidebar();
  const browserType = useBrowserType();
  const segments = useSegments();
  const isSidebarOpen = browserType === 'mobile' && sidebar.isSidebarOpen;
  const canOpenSidebar =
    (segments.at(-1) === '[conversationId]' || segments.at(-1) === '[elationId]') &&
    browserType === 'mobile' &&
    !sidebar.isSidebarOpen;

  return { ...sidebar, isSidebarOpen, canOpenSidebar };
};
