import React from 'react';

import { Button, ScrollView, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { Modal } from '~/modules/ui';

import { HowItWorks } from './HowItWorks';
import { Referral } from './Referral';
import { TermsOfReferral } from './TermsOfReferral';

import { themedStyles } from './styles';

import type { ModalProps } from '~/modules/ui';

export type ReferralModalProps = Omit<ModalProps, 'title'> & {
  referralCode?: string;
};

export const ReferralModal = (props: ReferralModalProps) => {
  const [styles] = useTheme(themedStyles);
  const { isDesktop } = useBrowserTypeMap();
  const { referralCode, ...restProps } = props;

  return (
    <Modal
      {...restProps}
      title="Refer Friends"
      containerStyle={isDesktop && styles.modal}
      showClose
      testID="ReferralModal"
    >
      <ScrollView contentContainerStyle={[styles.contentContainer, isDesktop && styles.contentContainerDesktop]}>
        <Text style={styles.textCenter}>
          {'Get $25 towards next year’s membership renewal for every friend who signs up for Almond.'}
        </Text>
        <Referral referralCode={referralCode} />
        <HowItWorks />
        <TermsOfReferral />
        <Button style={styles.doneButton} onPress={restProps.onRequestClose} size="m" testID="Done">
          {'Done'}
        </Button>
      </ScrollView>
    </Modal>
  );
};
