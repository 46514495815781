import { useChatContext } from 'stream-chat-react';

import { useRerender } from '~/modules/ui';
import { useCurrentUser } from '~/modules/user';

import { useChannelData } from './useChannelData';

import type { Channel } from 'stream-chat';

/**
 * Get information on the pinned status of a given channel, for the current user.
 * Note - this hook does not use the channel object from useChatContext()
 *        because it is used for arbitrary channels in the ChannelList. It
 *        must be able to operate on any channel, not just the selected one
 * @param channel The channel to get the pin information for
 * @returns Information on pin status, and method to toggle value
 */
export const usePin = (channel: Channel | undefined) => {
  const { client } = useChatContext();
  const currentUser = useCurrentUser();
  const streamId = currentUser?.streamId;
  const currentUserRole = client.user?.role;
  // We don't want to enable pinning for patients.
  const isAvailable = currentUserRole !== 'patient';
  const rerender = useRerender();
  const channelData = useChannelData(channel);
  const pinned = channelData?.pinned;
  const isPinned = !!streamId && pinned?.includes(streamId);

  const togglePin = async () => {
    if (!streamId) return;

    let updatedPinned = [...(pinned || [])];

    if (isPinned) {
      updatedPinned = updatedPinned.filter(pinnedItem => pinnedItem !== streamId);
    } else {
      updatedPinned.push(streamId);
    }

    await channel?.updatePartial({ set: { pinned: updatedPinned } });
    // PinButton isn't re-rendered as soon as the channel has been updated.
    // Another place where we need to use a force update.
    // It's not ideal, but al least we won't have any extra re-renders, but I'm open for better suggestions.
    rerender();
  };

  return { isAvailable, togglePin, isPinned };
};
