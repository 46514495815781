import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  header: {
    height: 62,
    backgroundColor: theme.lightSecondaryBackground,
    flexDirection: 'row',
    paddingHorizontal: sizes.SPACING_M,
  },

  left: {
    flex: 3,
    justifyContent: 'center',
  },

  center: {
    flex: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },

  right: {
    flex: 3,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },

  centerText: {
    color: theme.accent,
    textAlign: 'center',
  },
}));
