/* eslint-disable max-statements,max-lines,react/jsx-pascal-case,@typescript-eslint/naming-convention */

/// ///////////////////////////////////////////////////////////
// This component was created by
// 1. Copying the default MessageSimple source code from stream-chat-react
//    https://github.com/GetStream/stream-chat-react/blob/master/src/components/Message/MessageSimple.tsx
// 2. Updating imports
// 3. Riping out reactions
// 4. Riping out reply count button
// 5. Riping out bounce modal
// 6. Replacing the edit modal with the custom one
// 7. Replacing metadata with the custom one
// 8. Add an <Avatar> size prop
// 9. Mark if a message is from a patient or provider
// 10. Add providerType to the author name
//
// We will likely modify this component more in the future. These are the initial
// edits, but check the file's git history for more recent edits.
/// ///////////////////////////////////////////////////////////

import React, { useEffect } from 'react';
import { Modal, View } from 'react-native';

import { Avatar, Text, useTheme } from '@almond/ui';
import clsx from 'clsx';
import {
  areMessageUIPropsEqual,
  EditMessageForm as DefaultEditMessageForm,
  MessageErrorIcon,
  messageHasAttachments,
  MessageStatus as DefaultMessageStatus,
  MessageText,
  MML,
  useComponentContext,
  useMessageContext,
} from 'stream-chat-react';

import { getFullName } from '~/modules/user';

import { useDeleteMessage } from '../DeleteMessageProvider';
import { MessageInput } from '../MessageInput';
import { ActionsIcon } from './icons';
import { MessageOptions } from './MessageOptions';
import { ReplyButton } from './ReplyButton';

import { themedStyles } from './styles';

import type { MessageContextValue, MessageUIComponentProps } from 'stream-chat-react';

export const CUSTOM_MESSAGE_TYPE = {
  date: 'message.date',
  intro: 'channel.intro',
} as const;

type MessageSimpleWithContextProps = MessageContextValue;

const MessageSimpleWithContext = (props: MessageSimpleWithContextProps) => {
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    endOfGroup,
    firstOfGroup,
    groupedByUser,
    handleAction,
    handleRetry,
    highlighted,
    isMyMessage,
    message,
    onUserClick,
    onUserHover,
    renderText,
  } = props;

  const {
    Attachment,
    EditMessageInput = DefaultEditMessageForm,
    MessageStatus = DefaultMessageStatus,
  } = useComponentContext('MessageSimple');

  const hasAttachment = messageHasAttachments(message);

  const [styles] = useTheme(themedStyles);
  const deleteMessage = useDeleteMessage();
  const { getMessageActions } = useMessageContext('MessageActions');
  const messageActions = getMessageActions();
  const hasActionsOtherThanReply = messageActions.length > 1 || messageActions[0] !== 'quote';

  useEffect(() => {
    if (!editing) {
      clearEditingState();
    }
  }, [editing, clearEditingState]);

  if (!message || !isMyMessage || !handleRetry) return;

  if (message.customType === CUSTOM_MESSAGE_TYPE.date) {
    return null;
  }

  if (message.deleted_at || message.type === 'deleted') {
    // Hide deleted messages
    return null;
  }

  const showStatus = !groupedByUser || endOfGroup;
  const allowRetry = message.status === 'failed' && message.errorStatusCode !== 403;
  const showAuthor = !groupedByUser || firstOfGroup;

  const userName = getFullName(message.user);
  const userLabel = message.user?.label ? `, ${message.user.label}` : '';
  const label = `${userName}${userLabel}`;

  let handleClick: (() => void) | undefined;

  if (allowRetry) {
    handleClick = () => handleRetry(message);
  }

  const rootClassName = clsx(
    'str-chat__message str-chat__message-simple',
    'str-chat__message--'.concat(message.type),
    message.status && 'str-chat__message--'.concat(message.status),
    isMyMessage() ? 'str-chat__message--me str-chat__message-simple--me' : 'str-chat__message--other',
    message.text ? 'str-chat__message--has-text' : 'has-no-text',
    message.user?.role === 'patient' && 'str-chat__message--patient',
    {
      'str-chat__message--has-attachment': hasAttachment,
      'str-chat__message--highlighted': highlighted,
      'str-chat__message--pinned pinned-message': message.pinned,
      'str-chat__message-send-can-be-retried': allowRetry,
      'str-chat__virtual-message__wrapper--end': endOfGroup,
      'str-chat__virtual-message__wrapper--first': firstOfGroup,
      'str-chat__virtual-message__wrapper--group': groupedByUser,
    }
  );

  return (
    <>
      {editing && (
        <Modal visible={editing} transparent animationType="fade">
          <View
            style={[
              styles.editModalBackdrop,
              {
                /* @ts-ignore */
                $$css: true,
                _: 'str-chat',
              },
            ]}
          >
            <View style={styles.editModal}>
              <MessageInput
                clearEditingState={clearEditingState}
                Input={EditMessageInput as any}
                message={message}
                shouldSubmit={() => false}
                {...additionalMessageInputProps}
              />
            </View>
          </View>
        </Modal>
      )}
      {
        <div className={rootClassName} key={message.id}>
          {message.user && (
            <div
              className={clsx(`str-chat__avatar str-chat__message-sender-avatar`)}
              onClick={onUserClick}
              onMouseOver={onUserHover}
              title={message.user.name}
            >
              <Avatar size="s" image={message.user.image} user={message.user} />
            </div>
          )}
          {showAuthor && message.user && (
            <div className="str-chat__message-data str-chat__message-simple-data str-chat__message-author">
              <span className="str-chat__message-simple-name">
                <Text family="SystemFont" style={styles.authorName} size="s">
                  {label}
                </Text>
              </span>
            </div>
          )}
          <div
            className={clsx('str-chat__message-inner', {
              'str-chat__simple-message--error-failed': allowRetry,
            })}
            data-testid="message-inner"
            onClick={handleClick}
            onKeyUp={handleClick}
          >
            {hasActionsOtherThanReply && deleteMessage && (
              <MessageOptions ActionsIcon={ActionsIcon} handleDelete={() => deleteMessage(message)} />
            )}
            <div className="str-chat__message-bubble">
              {message.attachments?.length && !message.quoted_message ? (
                <Attachment actionHandler={handleAction} attachments={message.attachments} />
              ) : null}
              <MessageText message={message} renderText={renderText} />
              {message.mml && (
                <MML actionHandler={handleAction} align={isMyMessage() ? 'right' : 'left'} source={message.mml} />
              )}
              <MessageErrorIcon />
            </div>
            {!hasActionsOtherThanReply && <ReplyButton />}
          </div>
          {showStatus && (
            <div className="str-chat__message-data str-chat__message-simple-data str-chat__message-metadata">
              <MessageStatus />
            </div>
          )}
          {Array.isArray(message.editHistory) && message.editHistory.length ? (
            <div className="str-chat__message-edited">Edited</div>
          ) : null}
        </div>
      }
    </>
  );
};

const MemoizedMessageSimple = React.memo(
  MessageSimpleWithContext,
  areMessageUIPropsEqual
) as typeof MessageSimpleWithContext;

/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
export const MessageSimple = (props: MessageUIComponentProps) => {
  const messageContext = useMessageContext('MessageSimple');

  return <MemoizedMessageSimple {...messageContext} {...props} />;
};
